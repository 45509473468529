<!-- Normal view start-->

<div *ngIf="data.headerEnabled" class="promo-top w-100 h-auto text-center theme-container"
    [style.backgroundImage]="'url(' + serverPath2 + data.headerLogoSrc + ')'"
    onerror="this.src='assets/images/notification-back.jpg';">
    <h4 [style.color]="data.headerBannerTextColour" class="font-weight-normal text-uppercase text-white m-0">
        {{data.headerNotificationContent}}</h4>
</div>

<div *ngIf="display">
    <mat-toolbar class="primary"
        [ngClass]="{'sticky': isSticky, 'app-toolbar': data.headerEnabled === 1, 'appblock': data.headerEnabled !== 1}"
        fxHide fxShow.gt-md fxLayout="row" fxLayoutAlign="space-around center">

        <mat-toolbar-row class="theme-container" style="height: 100%;" fxLayoutAlign="space-around center">



            <section fxFlex="30">
                <div *ngIf="data.disableSearchInHeader==0" class="search-bar" fxHide fxShow.gt-sm fxShow.gt-sm fxLayout="row" fxLayoutAlign="start center"
                    fxFlex fxFlex.gt-sm="300px">
                    <calsoft-search [scroll]="false"></calsoft-search>
                </div>
            </section>


            <section>
                <calsoft-logo [sitename]="data.siteName" [type]="'light'"></calsoft-logo>

            </section>

            <section>

            </section>
            <section fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="5px">
                <calsoft-call [data]="data"></calsoft-call>
                <div *ngIf="languageSelection==1">
                    <calsoft-language style="color: #ffffff"></calsoft-language>
                </div>

                <div *ngIf="data.disableLoginHeader==0">
                <button *ngIf="!userLoggedIn" (click)="SignIn('login')" style="line-height: 15px" mat-button>
                    <div style="color: #ffffff" fxLayout="column">
                        <mat-icon>person</mat-icon>
                    </div>
                </button>
                </div>
                <div *ngIf="data.disableLoginHeader==0">
                <calsoft-HeaderUserProfileDropdown [data]="data" class="headerUser" *ngIf="userLoggedIn">
                </calsoft-HeaderUserProfileDropdown>
                </div>

                <calsoft-cart-icon [color]="false"></calsoft-cart-icon>
            </section>

        </mat-toolbar-row>

    </mat-toolbar>
    <div [ngClass]="{'sticky': isSticky}" class="app-toolbar primary" *ngIf="data.headerEnabled==1">
        <mat-toolbar class="primary" fxHide fxShow.gt-md fxLayout="row" fxLayoutAlign="center center"
            style="margin-bottom: 0px;height: -webkit-fill-available">

            <mat-toolbar-row class="theme-container" fxLayoutAlign="center center">
                <calsoft-Menu [color]="true" [data]="data" [type]="'two'" [categoryArray]="item"></calsoft-Menu>
            </mat-toolbar-row>
        </mat-toolbar>
    </div>

    <div  *ngIf="data.headerEnabled==0">
        <mat-toolbar class="primary" fxHide fxShow.gt-md fxLayout="row" fxLayoutAlign="center center"
            style="margin-top: 88px; margin-bottom: 0px;height: -webkit-fill-available">

            <mat-toolbar-row class="theme-container" fxLayoutAlign="center center">
                <calsoft-Menu [color]="true" [data]="data" [type]="'two'" [categoryArray]="item"></calsoft-Menu>
            </mat-toolbar-row>
        </mat-toolbar>
    </div>

</div>

<div *ngIf="!display">

    <div *ngIf="mobileHeaderType==1">
        <calsoft-mobile-header [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">

        </calsoft-mobile-header>
    </div>

    <div *ngIf="mobileHeaderType==0">
        <calsoft-mobile-header-new [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">

        </calsoft-mobile-header-new>
    </div>

    <div *ngIf="mobileHeaderType==2">
        <calsoft-mobile-header3 [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">

        </calsoft-mobile-header3>
    </div>

    <div *ngIf="mobileHeaderType==3">
        <calsoft-mobile-header4 [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">
        </calsoft-mobile-header4>
    </div>

    <div *ngIf="mobileHeaderType==4">
        <calsoft-mobile-header5 [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">
        </calsoft-mobile-header5>
    </div>

    <div *ngIf="mobileHeaderType==5">
        <calsoft-mobile-header6 [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">

        </calsoft-mobile-header6>
    </div>
    <div *ngIf="mobileHeaderType==6">
        <calsoft-mobile-header7 [item]="item" [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">
    
        </calsoft-mobile-header7>
    </div>
    <div *ngIf="mobileHeaderType==7">
    
        <calsoft-mobile-header8 [item]="item" [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">
    
        </calsoft-mobile-header8>
    </div>

</div>