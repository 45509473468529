<div *ngIf="display">
<mat-toolbar style="position: fixed;" fxHide fxShow.gt-md class="header-v2 app-toolbar primary"
    fxLayoutAlign="space-between center">
    <mat-toolbar-row  class="theme-container" fxLayoutAlign="space-between center" style="z-index: 1000; height: 100%;">

        
        <section fxFlex="32"  fxLayoutAlign="center center">
            <calsoft-logo [sitename]="data.siteName" [type]="'light'"></calsoft-logo>

        </section>

            <div  class="searchform" fxHide fxShow.gt-sm fxShow.gt-sm fxLayout="row"
                fxLayoutAlign="center center" fxFlex fxFlex.gt-sm="550px">
                <calsoft-search *ngIf="data.disableSearchInHeader==0"></calsoft-search>
            </div>
      

        <span fxFlex fxLayout="column">
            <span fxLayout="row" fxLayoutAlign="center center">

                <div *ngIf="data.disableWishlistHeader==0">
                <button style="color: white;" (click)="wishlist()" mat-button *ngIf="url != '/account/wishlist'">
                    <mat-icon>favorite_border</mat-icon>
                    
                </button>
                </div>
        
                <span *ngIf="url != '/cart'">
                    <calsoft-cart-icon [color]="false"></calsoft-cart-icon>
                </span>
        
                <div *ngIf="data.disableLoginHeader==0">
                <button *ngIf="!userLoggedIn" style="color: white;" (click)="SignIn('login')" mat-button>
                    <!-- <mat-icon>person</mat-icon> -->
                    <mat-icon>person_outline</mat-icon>
                </button>
                </div>
                <div *ngIf="data.disableLoginHeader==0">
                <calsoft-HeaderUserProfileDropdown [data]="data" *ngIf="userLoggedIn"></calsoft-HeaderUserProfileDropdown>
                </div>

            </span>
        
        </span>
    </mat-toolbar-row>

</mat-toolbar>
<mat-toolbar style="margin-top: 21px;" fxHide fxShow.gt-md>



    <mat-toolbar-row fxLayout="row" fxLayoutAlign="center center"
        style=" background: #fff;margin-top: 70px;
        box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12);padding: 0px;color: #000000;height: -webkit-fill-available"
        class="header-v2-bottom main-menu-wrap theme-container">

        <calsoft-Menu [data]="data" [type]="'one'" [categoryArray]="item"></calsoft-Menu>

    </mat-toolbar-row>

 
</mat-toolbar>

</div>
<div *ngIf="!display">

    <div *ngIf="mobileHeaderType==1">
        <calsoft-mobile-header [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">

        </calsoft-mobile-header>
    </div>

    <div *ngIf="mobileHeaderType==0">
        <calsoft-mobile-header-new [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">

        </calsoft-mobile-header-new>
    </div>

    <div *ngIf="mobileHeaderType==2">
        <calsoft-mobile-header3 [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">

        </calsoft-mobile-header3>
    </div>

    <div *ngIf="mobileHeaderType==3">
        <calsoft-mobile-header4 [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">
        </calsoft-mobile-header4>
    </div>

    <div *ngIf="mobileHeaderType==4">
        <calsoft-mobile-header5 [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">
        </calsoft-mobile-header5>
    </div>

    <div *ngIf="mobileHeaderType==5">
        <calsoft-mobile-header6 [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">

        </calsoft-mobile-header6>
    </div>
    <div *ngIf="mobileHeaderType==6">
        <calsoft-mobile-header7 [item]="item" [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">
    
        </calsoft-mobile-header7>
    </div>
    <div *ngIf="mobileHeaderType==7">
    
        <calsoft-mobile-header8 [item]="item" [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">
    
        </calsoft-mobile-header8>
    </div>

</div>